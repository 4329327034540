import { SubmissionError } from 'redux-form';

import sessionApi from 'api/sessionApi';

export const signUp = tutor => async () => {
  try {
    await sessionApi.signUp({ tutor });
  } catch (err) {
    throw new SubmissionError(err.errors);
  }
};
