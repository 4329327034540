import routesPaths from 'constants/routesPaths';
import SignUpPage from 'containers/SignUpPage';
import TermsAndConditionsPage from 'containers/TermsAndConditionsPage';
import NotFoundPage from 'containers/NotFoundPage';

const routes = [
  {
    path: routesPaths.termsAndConditions,
    component: TermsAndConditionsPage
  },
  {
    path: routesPaths.signUp,
    component: SignUpPage
  },
  {
    component: NotFoundPage
  }
];

export default routes;
