import React from 'react';
import { bool, func, node, string } from 'prop-types';
import cn from 'classnames';

const Button = ({ children, className, size, onPress, disabled, type }) => (
  <button
    className={cn('button primary', size, className)}
    onClick={onPress}
    disabled={disabled}
    type={type}
  >
    {children}
  </button>
);

Button.propTypes = {
  children: node.isRequired,
  className: string,
  size: string,
  onPress: func,
  disabled: bool,
  type: string
};

Button.defaultProps = {
  className: '',
  size: 'default',
  onPress: () => {},
  disabled: false,
  type: 'button'
};

export default Button;
