export default {
  'signup.title': 'Únete a nosotros!',
  'signup.text': 'Trabaja como tutor, gana dinero y comparte tus conocimientos con el mundo',
  'signup.termsAndConditionsDisclaimer': 'Al presionar "Enviar" está aceptando nuestros ',
  'signup.termsAndConditions': 'Terminos y Condiciones',
  'signup.form.firstName': 'Nombre',
  'signup.form.lastName': 'Apellido',
  'signup.form.birthDate': 'Fecha de nacimiento',
  'signup.form.email': 'Correo',
  'signup.form.password': 'Contraseña',
  'signup.form.passwordConfirmation': 'Confirmar contraseña',
  'signup.form.phone': 'Teléfono',
  'signup.form.state': 'Estado',
  'signup.form.city': 'Ciudad',
  'signup.form.zipCode': 'Código postal',
  'signup.form.submit': 'Enviar',
  'signup.form.success': `Gracias por registrarte, necesitamos revisar tu perfil antes de que puedas ser tutor.
  Te enviaremos actualizaciones por correo para hacerte saber el estado de tu solicitud.`,

  // errors
  'firstName.presence': 'Nombre no puede ser vacío',
  'lastName.presence': 'Apellido no puede ser vacío',
  'birthDate.presence': 'Fecha de nacimiento no puede ser vacía',
  'birthDate.ofAge': 'Debes ser mayor de edad para ser un tutor',
  'email.presence': 'Correo no puede ser vacío',
  'email.invalid': 'Correo deber ser válido',
  'password.presence': 'Contraseña no puede ser vacía',
  'passwordConfirmation.presence': 'Confirmar contraseña no puede ser vacía',
  'passwordConfirmation.equality': 'Debe ser igual a la contraseña'
};
