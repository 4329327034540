import React, { memo } from 'react';
import { func, bool } from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { injectIntl, intlShape, defineMessages, FormattedMessage } from 'react-intl';

import Loading from 'components/common/Loading';
import Input from 'components/common/Input';
import DateInput from 'components/common/DateInput';
import Button from 'components/common/Button';
import { validations, signUp } from 'utils/constraints';
import routes from 'constants/routesPaths';

const messages = defineMessages({
  firstName: { id: 'signup.form.firstName' },
  lastName: { id: 'signup.form.lastName' },
  email: { id: 'signup.form.email' },
  emailConfirmation: { id: 'signup.form.emailConfirmation' },
  password: { id: 'signup.form.password' },
  passConfirmation: { id: 'signup.form.passwordConfirmation' },
  birthDate: { id: 'signup.form.birthDate' },
  phone: { id: 'signup.form.phone' },
  state: { id: 'signup.form.state' },
  city: { id: 'signup.form.city' },
  zipCode: { id: 'signup.form.zipCode' }
});

export const SignUpForm = ({ handleSubmit, submitting, submitSucceeded, intl }) =>
  submitSucceeded ? (
    <p>
      <FormattedMessage id="signup.form.success" />
    </p>
  ) : (
    <>
      <h1>
        <FormattedMessage id="signup.title" />
      </h1>
      <p>
        <FormattedMessage id="signup.text" />
      </p>
      <form onSubmit={handleSubmit}>
        <h5 className="subheader">Personal info</h5>
        <div>
          <Field
            name="firstName"
            label={intl.formatMessage(messages.firstName)}
            component={Input}
          />
        </div>
        <div>
          <Field name="lastName" label={intl.formatMessage(messages.lastName)} component={Input} />
        </div>
        <div>
          <Field
            name="birthDate"
            label={intl.formatMessage(messages.birthDate)}
            component={DateInput}
          />
        </div>
        <div>
          <Field name="phone" label={intl.formatMessage(messages.phone)} component={Input} />
        </div>
        <div>
          <Field name="state" label={intl.formatMessage(messages.state)} component={Input} />
        </div>
        <div>
          <Field name="city" label={intl.formatMessage(messages.city)} component={Input} />
        </div>
        <div>
          <Field name="zipCode" label={intl.formatMessage(messages.zipCode)} component={Input} />
        </div>
        <h5 className="subheader">Account info</h5>
        <div>
          <Field
            name="email"
            label={intl.formatMessage(messages.email)}
            component={Input}
            type="email"
          />
        </div>
        <div>
          <Field
            name="emailConfirmation"
            label={intl.formatMessage(messages.emailConfirmation)}
            component={Input}
            type="email"
          />
        </div>
        <div>
          <Field
            name="password"
            label={intl.formatMessage(messages.password)}
            component={Input}
            type="password"
          />
        </div>
        <div>
          <Field
            name="passwordConfirmation"
            label={intl.formatMessage(messages.passConfirmation)}
            component={Input}
            type="password"
          />
        </div>
        <div className="terms-and-conditions-disclaimer">
          <FormattedMessage id="signup.termsAndConditionsDisclaimer" />
          <a href={routes.termsAndConditions}>
            <strong>
              <FormattedMessage id="signup.termsAndConditions" />
            </strong>
          </a>
        </div>
        <Button type="submit">
          <FormattedMessage id="signup.form.submit" />
        </Button>
        {submitting && <Loading />}
      </form>
    </>
  );

SignUpForm.propTypes = {
  handleSubmit: func.isRequired,
  submitting: bool.isRequired,
  submitSucceeded: bool.isRequired,
  intl: intlShape.isRequired
};

export default reduxForm({
  form: 'signUp',
  validate: validations(signUp, { fullMessages: false })
})(injectIntl(memo(SignUpForm)));
