import React, { memo } from 'react';
import moment from 'moment';

import { useDispatch } from 'hooks';
import { signUp } from 'actions/userActions';
import SignUpForm from 'components/user/SignUpForm';
import { DATE_FORMAT, DATE_FORMAT_BEAUTIFIED } from 'constants/dateFormats';

const SignUpPage = () => {
  const signUpRequest = useDispatch(signUp, [], user => ({
    ...user,
    birthDate: moment(user.birthDate, DATE_FORMAT_BEAUTIFIED).format(DATE_FORMAT)
  }));

  return (
    <div className="container sign-up-container">
      <SignUpForm onSubmit={signUpRequest} />
    </div>
  );
};

export default memo(SignUpPage);
