import validate from 'validate.js';

export const login = {
  email: {
    presence: { message: 'email.presence' },
    email: { message: 'email.invalid' }
  },
  password: {
    presence: { message: 'password.presence' }
  }
};

export const signUp = {
  firstName: {
    presence: { message: 'firstName.presence' }
  },
  lastName: {
    presence: { message: 'lastName.presence' }
  },
  birthDate: {
    presence: { message: 'birthDate.presence' }
  },
  phone: {
    presence: { message: 'phone.presence' }
  },
  state: {
    presence: { message: 'state.presence' },
    format: {
      pattern: '[A-Z]{2}',
      message: 'state.usePostalCode'
    }
  },
  city: {
    presence: { message: 'city.presence' }
  },
  zipCode: {
    presence: { message: 'zipCode.presence' }
  },
  email: {
    presence: { message: 'email.presence' },
    email: { message: 'email.invalid' }
  },
  emailConfirmation: {
    presence: { message: 'emailConfirmation.presence' },
    equality: {
      attribute: 'email',
      message: 'emailConfirmation.equality'
    }
  },
  password: {
    presence: { message: 'password.presence' }
  },
  passwordConfirmation: {
    presence: { message: 'passwordConfirmation.presence' },
    equality: {
      attribute: 'password',
      message: 'passwordConfirmation.equality'
    }
  }
};

export const validations = (constraints, props = {}) => data =>
  validate(data, constraints, props) || {};
