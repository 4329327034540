import React from 'react';
import { string, object } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

import { parseInputErrors } from 'utils/helpers';
import { DATE_FORMAT_BEAUTIFIED } from 'constants/dateFormats';

const DateInput = ({ input, label, meta: { touched, error } }) => {
  return (
    <div className="input-container">
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          inputVariant="outlined"
          format={DATE_FORMAT_BEAUTIFIED}
          margin="dense"
          label={label}
          views={['year', 'month', 'date']}
          {...input}
          value={input.value || null}
          error={Boolean(touched && error)}
        />
      </MuiPickersUtilsProvider>
      <span className="error">
        {touched && error && (
          <FormattedMessage id={parseInputErrors(error)} defaultMessage={parseInputErrors(error)} />
        )}
      </span>
    </div>
  );
};

DateInput.propTypes = {
  input: object.isRequired,
  label: string,
  type: string,
  placeholder: string,
  meta: object
};

DateInput.defaultProps = {
  type: 'text'
};

export default DateInput;
