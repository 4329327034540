import React from 'react';
import { string, object } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import TextField from '@material-ui/core/TextField';

import { parseInputErrors } from 'utils/helpers';

const Input = ({ input, label, type, meta: { touched, error } }) => {
  return (
    <div className="input-container">
      <TextField
        label={label}
        margin="dense"
        variant="outlined"
        {...input}
        type={type}
        error={touched && error}
      />
      <span className="error">
        {touched && error && (
          <FormattedMessage id={parseInputErrors(error)} defaultMessage={parseInputErrors(error)} />
        )}
      </span>
    </div>
  );
};

Input.propTypes = {
  input: object.isRequired,
  label: string,
  type: string,
  placeholder: string,
  meta: object
};

Input.defaultProps = {
  type: 'text'
};

export default Input;
