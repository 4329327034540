export default {
  'signup.title': 'Join us!',
  'signup.text': 'Work as a tutor, earn extra money and share your knowledge with the world',
  'signup.termsAndConditionsDisclaimer': 'By clicking "Submit" you agree with our ',
  'signup.termsAndConditions': 'Terms & Conditions',
  'signup.form.firstName': 'First name',
  'signup.form.lastName': 'Last name',
  'signup.form.birthDate': 'Birth date',
  'signup.form.email': 'Email',
  'signup.form.emailConfirmation': 'Email confirmation',
  'signup.form.password': 'Password',
  'signup.form.passwordConfirmation': 'Password confirmation',
  'signup.form.phone': 'Phone',
  'signup.form.state': 'State',
  'signup.form.city': 'City',
  'signup.form.zipCode': 'Zip code',
  'signup.form.submit': 'Submit',
  'signup.form.success': `Thanks for signing up, we need to review your profile before you can become a tutor.
  We will send you email updates to let you know the status of your application.`,

  // errors
  'firstName.presence': "First name can't be blank",
  'lastName.presence': "Last name can't be blank",
  'birthDate.presence': "Birth date can't be blank",
  'birthDate.ofAge': 'You must be of age in order to become a tutor',
  'phone.presence': "Phone can't be blank",
  'state.presence': "State can't be blank",
  'state.usePostalCode': 'Use state postal code',
  'city.presence': "City can't be blank",
  'zipCode.presence': "Zip code can't be blank",
  'email.presence': "Email can't be blank",
  'email.invalid': 'You must enter a valid email',
  'emailConfirmation.presence': "Email confirmation can't be blank",
  'emailConfirmation.equality': 'Must be equal to the email',
  'password.presence': "Password can't be blank",
  'passwordConfirmation.presence': "Password confirmation can't be blank",
  'passwordConfirmation.equality': 'Must be equal to the password'
};
